import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import GradientTitle from "../../GradientTitle";
import { IconType } from "../../Icon";
import IconTitleDescription from "../../IconTitleDescription";

interface ListingOptimizationProps {
  title: string[];
  subtitle: string;
  gradientPosition?: number;
  description: string;
  features?: {
    icon: IconType;
    title: string;
    description: string;
  }[];
}

const ListingOptimization: React.FC<ListingOptimizationProps> = props => {
  const { title, subtitle, description, features, gradientPosition } = props;

  return (
    <Section className="mt-18">
      <div className="w-full md:max-w-6xl">
        <Header variant={HeaderVariant.h5}>
          <span className="block mb-8 text-lg font-semibold text-indigo-1 text-center md:text-left">
            {subtitle}
          </span>
        </Header>

        <GradientTitle
          gradientPosition={gradientPosition}
          color="purple"
          variant={HeaderVariant.h2}
          className="text-center md:text-left"
          title={title}
          lineBreak={false}
        />
        <p className="md:text-4xl font-semibold text-center md:text-left">
          {description && description}
        </p>
      </div>
      <div className="grid grid-cols-1 justify-center items-center sm:grid-cols-2 lg:grid-cols-3 mt-18 text-center md:text-left">
        {features &&
          features.map((feature: any, index: number) => {
            return (
              <IconTitleDescription
                key={index}
                iconType={feature?.icon}
                title={feature?.title}
                description={feature?.description}
              />
            );
          })}
      </div>
    </Section>
  );
};

export default ListingOptimization;
