import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import { StaticImage } from "gatsby-plugin-image";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { nav } from "../../../utils/navigation";

interface SonarProps {
  title?: string;
  subTitle?: string;
  description?: string[];
  image?: any;
  cta?: {
    text: string;
    route: string;
  };
}

const Sonar: React.FC<SonarProps> = props => {
  const { title, subTitle, description, cta } = props;

  return (
    <div className="bg-indigo-3-light rounded-tl-large rounded-br-large">
      <Section className="pt-20 pb-20">
        <p className="mb-6 text-indigo-3 font-semibold text-center md:text-left">
          {subTitle}
        </p>
        <div className="flex flex-col lg:flex-row items-center justify-between text-center md:text-left">
          <div className="w-full lg:w-1/2">
            <Header
              className="md:text-5xl md:font-bold mb-8 lg:max-w-xl"
              variant={HeaderVariant.h2}
            >
              {title}
            </Header>
            <div className="lg:max-w-xl">
              {description?.map((item, index) => {
                return (
                  <p key={index} className="mb-3 font-semibold">
                    {item}
                  </p>
                );
              })}
            </div>
            <Button
              variant={ButtonVariant.indigoLight}
              size={ButtonSize.large}
              text={cta?.text}
              className="mx-auto lg:mx-0 mt-6 mb-6 lg:mb-0"
              onClick={() => {
                nav(cta?.route);
              }}
            />
          </div>
          <div className="w-full lg:w-1/2 mt-12 lg:mt-0 ">
            <div>
              <StaticImage
                src="../../../images/amazon-seo-tool/sonar-search-example.png"
                alt="Sonar tool in action"
                className="w-full"
              />
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Sonar;
