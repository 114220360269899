import React from "react";
import Section from "../../containers/Section";
import cn from "classnames";

interface QuoteProps {
  quote: { 
    text: string; 
    author: { 
      name?: string 
      img?: any
    }; 
  };
  bgColor?: string;
  textColor?: string;
}

const FullWidthQuote: React.FC<QuoteProps> = props => {
  const { quote, bgColor = "bg-gradient-to-r from-blue-3 to-indigo-3-light", textColor } = props;

  return (
    <Section className="md:py-12 lg:py-18">
      <div className={cn("p-8 sm:py-12 sm:px-16 shadow-lg rounded-2xl mx-auto", bgColor && bgColor)}>
        <p className={cn("text-2xl sm:text-4xl mx-auto font-semibold", textColor && textColor)}>
          {quote?.text}
        </p>
        <div className="flex flex-row items-center mt-6">
          {quote?.author?.img}
          <small className="ml-4 font-semibold sm:max-w-[230px]">{quote?.author?.name}</small>
        </div>
      </div>
    </Section>
  );
};

export default FullWidthQuote;
