import React from "react";
import Section from "../../../containers/Section";
import { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import GradientTitle from "../../GradientTitle";
import { StaticImage } from "gatsby-plugin-image";
import RowImageContent from "../../RowImageContent";

interface FindYourProductsProps {
  title: string[];
  gradientPosition?: number;
  description: string;
  features?: {
    img: typeof StaticImage;
    title: string;
    description: string;
    imgOrder?: string;
    contentClass?: string;
  }[];
  subtitle?: string;
}

const FindYourProducts: React.FC<FindYourProductsProps> = props => {
  const { title, description, features, gradientPosition } = props;

  return (
    <Section>
      <div className="text-center pb-20 md:pt-28 md:pb-24">
        <div className="m-auto w-full lg:max-w-5xl lg:px-8">
          <GradientTitle
            gradientPosition={gradientPosition}
            color="purple"
            variant={HeaderVariant.h2}
            title={title}
            lineBreak={false}
          />
          <Paragraph className="text-2xl md:px-8">
            {description && description}
          </Paragraph>
        </div>
      </div>
      <div>
        {features &&
          features.map((feature: any, index: number) => {
            return (
              <RowImageContent
                key={index}
                headerVariant={HeaderVariant.h2}
                title={feature?.title}
                description={[feature?.description]}
                image={feature?.img}
                imgOrder={feature.imgOrder}
                contentClassName={feature.contentClassName}
              />
            );
          })}
      </div>
    </Section>
  );
};

export default FindYourProducts;
