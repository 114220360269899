import React from "react";
import Icon from "../Icon";
import Header, { HeaderVariant } from "../Header";

interface IconTitleDescriptionProps {
  title?: string;
  description?: string[];
  iconType?: any;
  iconBg?: string;
  iconFill?: string;
}

const IconTitleDescription: React.FC<IconTitleDescriptionProps> = props => {
  const {
    title,
    description,
    iconType,
    iconBg = "bg-blue-3-light",
    iconFill
  } = props;

  return (
    <div className="mb-6 lg:mb-0">
      <span className={`rounded-full inline-block p-3 ${iconBg}`}>
        <Icon className={`w-6 h-6 ${iconFill}`} iconType={iconType} />
      </span>
      <div className="md:max-w-md md:pr-12 md:min-h-[146px]">
        <Header variant={HeaderVariant.h4}>
          <span className="block font-semibold text-2xl my-2">{title}</span>
        </Header>
        <p className="text-base">{description}</p>
      </div>
    </div>
  );
};

export default IconTitleDescription;
