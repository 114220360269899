import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/Seo";
import "../components/VideoAdsPage/video-ads-page.scss";
import Layout from "../containers/Layout";
import HeroSection from "../components/HeroSection";
import { IconType } from "../components/Icon";
import BottomCTA from "../components/BottomCta";
import { useTranslation } from "gatsby-plugin-react-i18next";
import FindYourProducts from "../components/AmazonSEOToolPage/FindYourProducts";
import ListingOptimization from "../components/AmazonSEOToolPage/ListingOptimization";
import { Name, Type, Location, Category } from "../utils/analytics/constants";
import { useSegment } from "../utils/analytics";
import { nav } from "../utils/navigation";
import Section from "../containers/Section";
import Sonar from "../components/AmazonSEOToolPage/Sonar";
import { PAGE } from "../constants/pages";
import FullWidthQuote from "../components/FullWidthQuote";
import Header, { HeaderVariant } from "../components/Header";

interface AmazonSEOToolProps {
  data: any;
}

const AmazonSEOTool: React.FC<AmazonSEOToolProps> = ({ data }) => {
  const pageData = data;
  const { t } = useTranslation();
  const { helpers } = useSegment();

  const heroCta = {
    text: t("Get Started"),
    route: t("/demo")
  };

  const findYourProductsFeatures = [
    {
      img: (
        <StaticImage
          src="../images/amazon-seo-tool/search-insights.png"
          alt="Search Insights"
          className="w-full"
        />
      ),
      title: t("Search Insights"),
      description: t(
        "Find all relevant terms for the brand, product, and search term level data for the top 80,000 terms for free and pay thereafter. Search terms come with performance segmented by ad units."
      ),
      imgOrder: "order-2 lg:order-1",
      contentClassName: "lg:pl-10"
    },
    {
      img: (
        <StaticImage
          src="../images/amazon-seo-tool/share-of-voice.png"
          alt="Share of Voice"
          className="w-full"
        />
      ),
      title: t("Share of Voice"),
      description: t(
        "Track the share of voice for your brand or any of your competing brands on Amazon. Look at daily or weekly trends making it super seamless for you to track your brand placement."
      ),
      imgOrder: "order-2",
      contentClassName: "lg:pr-10"
    },
    {
      img: (
        <StaticImage
          src="../images/amazon-seo-tool/organic-rank.png"
          alt="Organic Rank Tracking"
          className="w-full "
        />
      ),
      title: t("Organic Rank Tracking"),
      description: t(
        "Track organic rank for the top 50 ASINs for every search term. Be informed on your ASIN ranks and set your products apart for sound Amazon SEO success."
      ),
      imgOrder: "order-2 lg:order-1",
      contentClassName: "lg:pl-10"
    }
  ];

  const listingOptimizationFeatures = [
    {
      icon: IconType.searchInsights,
      title: t("Search term tracking"),
      description: t(
        "Understand how products and brands are spending and their results on Amazon search."
      )
    },
    {
      icon: IconType.competitor,
      title: t("Customer insights"),
      description: t(
        "Stay ahead of the game by tracking competitors’ search results and spending on search terms. Our Amazon SEO tool makes sure you’re one step ahead of your competitors."
      )
    },
    {
      icon: IconType.processingIndigo,
      title: t("Always-on monitoring for all hours of the day"),
      description: t(
        "For every search on Amazon, search result rankings vary by hour and day. Our software monitors Amazon SERP at hours of the day to fetch you an accurate daily average and a consistent SEO trend."
      )
    },
    {
      icon: IconType.tracking,
      title: t("Share of voice tracking"),
      description: t(
        "Worrying about customer mindshare for your category of products? For any search term, you can now get a percentage of search results made up by your search terms."
      )
    },
    {
      icon: IconType.keywordBoost,
      title: t("Keyword boost"),
      description: t(
        "One of the most predictable ways to influence organic rank is definitely by bringing more sales through advertising. Increase your impression share by boosting your products for flagship category keywords and improve your Amazon SEO rankings now."
      )
    }
  ];

  const quote = {
    text: t(
      "It’s crucial for us to have granularity and control when it comes to optimizing ads. The flexibility of the Perpetua platform enables this."
    ),
    author: {
      name: "Momentum Commerce",
      img: (
        <StaticImage
          src="../images/amazon-seo-tool/momentum.png"
          alt="quote"
          width={64}
          height={64}
        />
      )
    }
  };

  const sonarCTA = {
    text: t("Try Sonar Free"),
    route: t("/sonar-amazon-keyword-tool")
  };

  return (
    <Layout>
      <SEO
        title={t("Amazon SEO Tool")}
        description={t(
          "The leading Amazon SEO Tool to grow organic sales with fully optimized, high-ranking product listings. Drive impressions, increase CTR and maximize conversions."
        )}
        image={`https://perpetua.io${pageData?.openGraphImage?.publicURL}`}
      />

      <HeroSection
        bgGradient="bg-gradient-to-b from-purple-3-light to-white via-white"
        page={PAGE.AMAZON_SEO_TOOL}
        gradientTitle={[
          t("Grow your Amazon SEO"),
          t("with search insights & keyword rank monitoring tools")
        ]}
        gradientPosition={1}
        titleClassName="!max-w-2xl pr-8"
        h1Title={t("Amazon SEO Solutions")}
        icon={IconType.amazon}
        cta={heroCta}
        imageReplacement={
          <StaticImage
            src="../images/amazon-seo-tool/hero-img.png"
            alt="Amazon SEO tool"
            className="w-full hidden md:block"
          />
        }
      />

      <FindYourProducts
        title={[
          t("Customers need to "),
          t("find your products"),
          t("before they can buy them")
        ]}
        gradientPosition={2}
        description={t(
          "Maintaining your keyword ranking and monitoring is vital to your Amazon business. With this Amazon SEO tool, you can achieve your maximum SEO results in a few simple steps."
        )}
        features={findYourProductsFeatures}
      />

      <ListingOptimization
        title={[
          t("How can you get your products to"),
          t("rank higher on Amazon"),
          t("search results?")
        ]}
        subtitle={t("Listing Optimization")}
        gradientPosition={2}
        description={t(
          "You can improve your Amazon ranking as well as increase traffic and sales by simply using search insights."
        )}
        features={listingOptimizationFeatures}
      />

      <FullWidthQuote textColor="text-indigo-3-dark" quote={quote} />

      <BottomCTA
        primaryButtonId={""}
        secondaryButtonId={""}
        title={t("Ready to try the SEO module?")}
        primaryButtonText={t("Get Started")}
        primaryButtonOnClick={() =>
          helpers
            .delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: "Get Started",
              page_url: window?.location.href,
              destination: t("/demo/"),
              location: Location.BottomCta
            })
            .then(() => {
              nav(t("/demo/"));
            })
        }
      />

      <Section className="py-18">
        <div className="flex mx-auto items-center justify-center">
          <div>
            <StaticImage
              src="../images/amazon-seo-tool/chart-pie.png"
              alt="Amazon SEO Tool"
              width={310}
              height={162}
              className="w-full"
            />
          </div>

          <div className="ml-4">
            <Header variant={HeaderVariant.h3}>
              <span className="block text-base font-normal text-gray-1 mb-2">
                {" "}
                {t(
                  "81% of clicks are on brands on the first page of search results"
                )}
              </span>
            </Header>
            <p className="font-semibold text-xl text-gray-1">
              {t("Search Engine Journal, 2018")}
            </p>
          </div>
        </div>
      </Section>

      <Sonar
        title={t("Discover new keywords for free, using Sonar")}
        subTitle={t("Sonar")}
        description={[
          t(
            "Sonar is a free keyword research tool designed explicitly for Amazon listings. Sonar contains a database of millions of real Amazon search queries. Type in anything to find a list of keywords for you to use, including matching keywords, competitor’s keywords, and more, and download and use them for your Amazon SEO."
          ),
          t(
            "Sonar displays the Amazon search volume for each of the keywords and can be used to research keywords for Amazon.de (Germany) and for Amazon.com (US)."
          )
        ]}
        cta={sonarCTA}
      />
    </Layout>
  );
};

export default AmazonSEOTool;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    openGraphImage: file(relativePath: { eq: "amazon-seo-tool/ogImage.png" }) {
      publicURL
    }
  }
`;
